body {
  background-image: url(/src/Images/login-bg-img.png);
  background-color: #D2EBFF;
}

.delete {
  
}
.delete:hover {
  color: blue;
}